import React from "react"

import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"

const Success = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "short" }} />
      <Layout>
        <Container fluid className="info">
          <Container className="info-contain">
            <Row>
              <Col md={12}>
                <h4 className="info-title">Thanks!</h4>
              </Col>
              <Col md={12}>
                <p className="info-text-large">I'll get back to you soon!</p>
              </Col>
            </Row>
          </Container>
        </Container>
      </Layout>
    </>
  )
}

export default Success
